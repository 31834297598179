<template>
    <div class="container" v-html="htmlContent"></div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        htmlContent: '',
      };
    },
    async mounted() {
      try {
        const response = await fetch('/konten.txt');
        if (response.ok) {
          const text = await response.text();
          this.htmlContent = text;
        } else {
          console.error('Failed to fetch konten.txt');
        }
      } catch (error) {
        console.error('An error occurred while fetching konten.txt:', error);
      }
    },
  };
  </script>