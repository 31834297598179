<template>
  <header class="site-header  site-header--menu-right fugu--header-section fugu--header-three" id="sticky-menu">
    <div class="c-ddmIJY"></div><br/>
    <div class="container-fluid">
      <nav class="navbar site-navbar">
        <div class="brand-logo">
          <a href="/" aria-label="Great OLYMPUS1000">
            <img
              id="logobrand"
              alt=""
              srcset="https://cdn-master.it-cg.group/olympus1000/63d7d755-86c6-479f-841e-94482daaf984.gif"
              src="https://cdn-master.it-cg.group/olympus1000/63d7d755-86c6-479f-841e-94482daaf984.gif"
              width="350"
              height="45"
              decoding="async"
              data-nimg="1"
              class="light-version-logo"
              loading="lazy"
            />
          </a>
        </div>
        <div class="header-btn desktop header-btn-l1 ms-auto d-flex d-none d-xs-inline-flex">
          <a class="fugu--btn fugu--menu-btn1" href="https://urlshortenertool.com/RTP-RM">DAFTAR</a>
        </div>
        <div @click="mobileMenu" class="mobile-menu-trigger"><span></span></div>
      </nav>
      <nav class="menu-block" id="append-menu-header">
        <div class="mobile-menu-head">
          <div class="mobile-menu-close" @click="closeMenu">×</div>
        </div>
        <ul class="site-menu-main">
          <li class="nav-item nav-item-has-children desktop-d-none">
            <ul class="sub-menu active nav-item" id="submenu-2">
              <div style="padding: 5px !important;" class="c-hyHPZu">
                <span class="c-eBIusB c-eBIusB-cslBQi-variant-body3Regular c-eBIusB-gjdJOs-align-center c-dFfuhX">
                  Selamat Datang
                </span>
                <span class="c-eBIusB c-eBIusB-cslBQi-variant-body3Regular c-eBIusB-gjdJOs-align-center c-dFfuhX">
                  Ayo, bermain dan mulailah untuk menang !
                </span>
                <div class="c-PJLV c-fbYheq"></div>
                <div class="c-dhzjXW" style="display: flex; justify-content: center; padding: 5px !important;">
                  <div class="c-hJhMRA">
                    <div class="c-izIffB">
                      <button class="c-gpIAIC c-gpIAIC-jDApLP-variant-medium c-gpIAIC-SbkoU-buttonType-primary">
                        <span class="c-eBIusB c-eBIusB-fmKBWT-variant-body2Medium c-dFfuhX">Masuk</span>
                      </button>
                    </div>
                  </div>
                  <div class="c-PJLV c-kogEPa" style="width: 20px;"></div> <!-- Adjust the width as needed -->
                  <div class="c-hJhMRA">
                    <a class="c-cIdiJW" href="/">
                      <div class="c-izIffB">
                        <button class="c-gpIAIC c-gpIAIC-jDApLP-variant-medium c-gpIAIC-SbkoU-buttonType-primary">
                          <span class="c-eBIusB c-eBIusB-fmKBWT-variant-body2Medium c-dFfuhX">Daftar</span>
                        </button>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <li class="sub-menu--item">
                <a class="drop-trigger" href="/">Menu 1</a>
              </li>
              <li class="sub-menu--item">
                <a class="drop-trigger" href="/">Menu 2</a>
              </li>
              <li class="sub-menu--item">
                <a class="drop-trigger" href="/">Menu 3</a>
              </li>
              <li class="sub-menu--item">
                <a class="drop-trigger" href="/">Menu 4</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="c-ddmIJYz"></div>
  </header>
</template>

<script>
export default {
  name: 'HeaderLayout',
  methods: {
    mobileMenu(e) {
      e.preventDefault();
      var element = document.getElementById("append-menu-header");
      element.classList.add("active");
    },
    closeMenu(e) {
      e.preventDefault();
      var element = document.getElementById("append-menu-header");
      element.classList.remove("active");
    }
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>
