<template>
  <footer class="fugu--footer-section">
    <div class="container">
      <Livechat />
      <div class="fugu--footer-bottom" style="color: white">
        © Copyright 2024 All Rights Reserved by OLYMPUS1000
      </div>
  </div>
  <div class="olympuscontainer">
    <a href="https://wa.style/RTP-AKURAT-OLYMPUS1000" target="_blank">
        <nuxt-img src="https://ucarecdn.com/c5db0dad-f4cd-444a-84c5-d8b1adf1b7af/" class="rtpbutton" alt="RTP Live"/>
    </a>
    <a href="http://wa.style/Telegram-Olympus1000" target="_blank">
        <nuxt-img src="https://ucarecdn.com/333fb7fd-faf5-4d75-ac78-be0bdf468a7c/" class="telegrambutton" alt="Chat Telegram"/>
    </a>
    <a href="http://wa.style/Whatsapp-Olympus1000" target="_blank">
        <nuxt-img src="https://ucarecdn.com/680ed24d-bfc8-49ba-bb59-80b7f2180c83/" class="wabutton" alt="Chat WA"/>
    </a>
    <a href="https://storage.googleapis.com/cuan-mobile-apk-download/OLYMPUS1000.apk">
        <nuxt-img src="https://ucarecdn.com/a9f04091-1eca-4c6d-9818-e2f5dceeae14/" alt="Aplikasi"/>
    </a>
  </div>

  </footer>
</template>

<style>
.olympuscontainer {
    display: flex;
    gap: 10px;
    flex-direction: column;
    position: fixed;
    left: 1%;
    bottom: 20px;
    z-index: 999;
}
.olympuscontainer a {
    width: 65px;
    height: auto;
}
a:not([class]) {
    text-decoration-skip-ink: auto;
}
.olympuscontainer a img {
    width: 100%;
}
</style>