<template>
    <div id="sectmobile" class="fugu--hero-section">
      <BannerSlide />
    </div>
</template>

<script>
import BannerSlide from "./BannerSlide.vue";
import Checkup from "./Checkup.vue";

export default {
  components: {
    BannerSlide,
    Checkup,
  },
  data() {
    return {
      svgStyle: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 0,
        margin: "-1px",
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        width: "1px",
      },
    };
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
