<template>
  <div class="resbanner" style="margin-top: -40px;">
    <swiper pagination :modules="[Autoplay, Pagination, Navigation]" :loop="true" :autoplay="{ delay: 5000 }" class="mySwiper">
      <swiper-slide v-for="(image, index) in images" :key="index">
        <nuxt-img :src="image.src" :alt="image.alt" class="carousel__item" loading="lazy"/>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import 'swiper/css'
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const images = ref([
        { src: 'https://cdn-master.it-cg.group/olympus1000/55d21e7c-8cde-4d04-a53f-439b1187c499.jpeg', alt: 'Welcome Banner 1' },
        { src: 'https://cdn-master.it-cg.group/olympus1000/75aa71af-14ed-4cea-8f42-6e322907c9d9.jpeg', alt: 'Welcome Banner 2' },
        { src: 'https://cdn-master.it-cg.group/olympus1000/511e4bc6-0ede-49eb-98f6-002547860ab1.jpeg', alt: 'Welcome Banner 3' },
        { src: 'https://cdn-master.it-cg.group/olympus1000/cf27de02-2ca6-40f4-8c8b-7778a944c5a4.jpeg', alt: 'Welcome Banner 4' },
        { src: 'https://cdn-master.it-cg.group/olympus1000/cf76f781-b03d-40f1-93f5-32f37cdb8c4e.jpeg', alt: 'Welcome Banner 5' },
]);
</script>

<style>
.carousel__item {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
  background: #3a0f57 !important;
  opacity: 1;
  box-shadow: 0 0 10px rgba(151, 29, 233, 0.7);
  transform: scale(1.2);
}

.swiper-pagination-bullet:hover {
  background: rgba(255, 255, 255, 0.7);
}
</style>
